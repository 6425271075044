import React, { useCallback, useEffect } from 'react';
import { redirect } from 'src/utils/url-utils';
import { Grid } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { WindowLocation } from '@reach/router';

import { CardGridWrapper, WorkoutCard } from 'src/components/shared';
import Layout from 'src/components/layout/Layout';

import Selectors from 'src/state/root-selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import * as Styles from 'src/styles/new-workouts.module.scss';

const connector = connect((state: IState) => ({
    favorites: Selectors.favorites.favoriteWorkouts(state),
    loggedIn: Selectors.auth.loggedIn(state),
}));

interface FavoritesProps extends ConnectedProps<typeof connector> {
    location: WindowLocation;
}

const Favorites = (props: FavoritesProps) => {
    const { favorites, loggedIn, location } = props;
    const hasWorkouts = !!favorites.length;

    const renderCards = useCallback(() => {
        return favorites.map((workout, index: number) => (
            <WorkoutCard key={`workout-card-${index}`} workout={workout} />
        ));
    }, [favorites]);

    useEffect(() => {
        if (!loggedIn) {
            redirect('/login');
        }
    }, [loggedIn]);

    if (!loggedIn) {
        return null;
    }

    const pageContext = {};

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={Styles.wrapper}>
                <Grid>
                    <Grid.Row className="page-section">
                        <Grid.Column>
                            <Grid container>
                                <Grid.Row verticalAlign="bottom" className="padding--0">
                                    <Grid.Column width={10} floated="left">
                                        <h2 className="upper bold">Favorites</h2>
                                    </Grid.Column>
                                    <Grid.Column width={6} floated="right" textAlign="right">
                                        <span>
                                            {favorites.length} Workout
                                            {favorites.length > 1 || favorites.length === 0
                                                ? 's'
                                                : ''}
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>

                                {hasWorkouts && <CardGridWrapper>{renderCards()}</CardGridWrapper>}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default connector(Favorites);
